.footer-basic {
  padding:25px 0;
  background-color:#505050;
  color:whitesmoke;
}

.footer-basic ul {
  padding:0;
  list-style:none;
  text-align:center;
  font-size:14px;
  line-height:1.6;
  margin-bottom:0;
}

.footer-basic li {
  padding:0 10px;
}

.footer-basic ul a {
  text-transform: uppercase;
  background: none;
  border: none;
  color:inherit;
  text-decoration:none;
  opacity:0.8;
  cursor: pointer;
}

.footer-basic ul a:hover {
  opacity:1;
}

.footer-basic .copyright {
  margin-top:15px;
  text-align:center;
  font-size:13px;
  color:#bbbbbb;
  margin-bottom:0;
}

.logo-foot{
  text-align: center;
  margin: 50px auto;
  
}

.img-foot {
  filter: brightness(0) invert(1);
}

.container-yas {
  
  width: 100%;
  
  padding: 2rem;
  overflow: hidden;
  /* display: flex; */
  align-items: center;
  justify-content: center
}

.formy {
  margin-top:20px;
  margin-bottom:40px;
  width: 100%;
  /* max-width: 820px; */
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  
}

.contact-info-form {
  padding:2.3rem  ;
  background-color: #006699;
  position: relative;
  vertical-align: middle;
  
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #a7323254);
  position: absolute
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px
}

.contact-info-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #006699;
  transform: rotate(45deg);
  bottom: 66px;
  left: -13px
}

.titlec {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem
}

.contact-info-form p{
  color: #fff;
  
  
  
}


.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative
}

.contact-info .title {
  color: #006699
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0
}

.social-information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem
}


.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #006699;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3
}

.social-information i {
  font-size: 22px;
  margin-bottom: 23px;
  margin-right: 8px;
  color: #cf0606
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #006699, #617e8d);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%)
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px)
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2
}

@media (max-width: 850px) {
  .formy {
      grid-template-columns: 1fr
  }

  .contact-info:before {
      bottom: initial;
      top: -75px;
      right: 65px;
      transform: scale(0.95)
  }

  .contact-info-form:before {
      top: -13px;
      left: initial;
      right: 70px
  }

  .square {
      transform: translate(140%, 43%);
      height: 350px
  }

  .big-circle {
      bottom: 75%;
      transform: scale(0.9) translate(-40%, 30%);
      right: 50%
  }

  .text {
      margin: 1rem 0 1.5rem 0
  }

  .social-media {
      padding: 1.5rem 0 0 0
  }
}

@media (max-width: 480px) {
  .container-yas {
      padding: 1.5rem
  }

  .contact-info:before {
      display: none
  }

  .square,
  .big-circle {
      display: none
  }

 
  .contact-info {
      padding: 1.7rem 1.6rem
  }

  .text,
  .social-information,
  .social-media p {
      font-size: 0.8rem
  }

  .titlec {
      font-size: 1.15rem
  }

  
}