.navbar-logos {
    background-image: url("../../../assets/header-bkg-blue.webp");
    background-position: 33% center;

    /*backdrop-filter: blur(15px);*/
    /*background: rgba(255, 255, 255, 0.9) !important;*/
}

.navbar-links {
    background: rgb(0, 98, 155);
    box-shadow: rgba(17, 12, 46, 0.2) 0 0.25rem 2rem 0;
}

.navbar-links .hamburger-react {
    margin: -7px;
}

.navbar-links .navbar-nav .nav-link {
    font-size: 1.03em;
    text-decoration: none;
    cursor: pointer;
}

.navbar-links .nav-item {
    margin: 0 16px 0 16px;
}

.navbar-links .nav-item .dropdown-menu {
    background: rgb(0, 98, 155);
    box-shadow: rgba(17, 12, 46, 0.2) 0 0.25rem 2rem 0;
}
.navbar-links .nav-item .dropdown-item {
    background: rgb(0, 98, 155);
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.781);
}
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.932);
}
